import * as yup from 'yup';

import { makeRequired, makeNullable } from 'lib/validators';
import FormAddressFields from 'components/form/FormAddressFields';

const ADDRESS_FIELDS = ['address1', 'address2', 'city', 'stateProv', 'postalCode', 'country'];

const DEFAULT_GET_SUB_FIELD_NAME = (defaultAddressFieldName) => defaultAddressFieldName;

const getAddressFieldsConfig = ({
  validator = 'optional',
  fieldName = null,
  getSubFieldName = DEFAULT_GET_SUB_FIELD_NAME,
  values = {},
  hideLabels = false,
}) => {
  if (!['optional', 'required', 'off'].includes(validator)) {
    throw new Error(`Unknown validator: "${validator}"`);
  }

  const addressFieldsConfig = {
    validationRules: null,
    defaultValues: {},
    renderFields: () => null,
  };

  // Validation Rules
  const validationModifier = {
    required: makeRequired,
    optional: makeNullable,
    off: (x) => x.strip(),
  }[validator];

  const subfieldValidationSchema = ADDRESS_FIELDS.reduce((validators, prop) => {
    const subFieldName = getSubFieldName(prop);

    // Unless off entirely, address2 should always be nullable
    if (prop === 'address2' && validator !== 'off') {
      return { ...validators, [subFieldName]: makeNullable(yup.string()) };
    }

    // Remove country if no state/prov provided when address is optional
    if (prop === 'country' && validator === 'optional') {
      return {
        ...validators,
        [subFieldName]: yup
          .string()
          .when([getSubFieldName('stateProv')], ([stateProv], schema) =>
            validationModifier(schema.transform((x) => (stateProv ? x : '')))
          ),
      };
    }

    return { ...validators, [subFieldName]: validationModifier(yup.string()) };
  }, {});

  if (!fieldName) {
    addressFieldsConfig.validationRules = subfieldValidationSchema;
  } else if (validator === 'optional') {
    addressFieldsConfig.validationRules = {
      [fieldName]:
        // The address object gets evaluated before any of the child
        // properties get transformed, so in an optional address where
        // the user has not filled out anything the value will have a
        // truthy value for "country" (our default of US) despite the
        // fact that it will get nulled via transform immediately after.
        //
        // This custom transform checks to see if the ONLY filled out
        // field is country, and returns null if so, otherwise performing
        // our usual makeNullable transform
        validator === 'optional'
          ? yup
              .object(subfieldValidationSchema)
              .nullable()
              .transform((value) => {
                const providedKeys = Object.entries(value).reduce(
                  (list, [k, v]) => (v ? [...list, k] : list),
                  []
                );

                // Address only contains a country)
                if (providedKeys.length === 1 && providedKeys[0] === getSubFieldName('country')) {
                  return null;
                }

                return providedKeys.length ? value : null;
              })
          : validationModifier(yup.object(subfieldValidationSchema)),
    };
  } else {
    addressFieldsConfig.validationRules = {
      [fieldName]: validationModifier(yup.object(subfieldValidationSchema)),
    };
  }

  if (validator !== 'off') {
    // Default Values
    const subfieldValues = ADDRESS_FIELDS.reduce((defaults, prop) => {
      const subFieldName = getSubFieldName(prop);
      const subFieldValue =
        values && (fieldName ? values[fieldName]?.[subFieldName] : values[subFieldName]);
      const fallbackValue = prop === 'country' ? 'US' : '';
      return { ...defaults, [subFieldName]: subFieldValue || fallbackValue };
    }, {});

    addressFieldsConfig.defaultValues = fieldName
      ? { [fieldName]: subfieldValues }
      : subfieldValues;

    // Component rendering
    addressFieldsConfig.renderFields = ({ label, helpText } = {}) => (
      <FormAddressFields
        label={label}
        helpText={helpText}
        isRequired={validator === 'required'}
        getFieldName={(prop) =>
          fieldName ? `${fieldName}.${getSubFieldName(prop)}` : getSubFieldName(prop)
        }
        hideLabels={hideLabels}
      />
    );
  }

  return addressFieldsConfig;
};

export default getAddressFieldsConfig;
