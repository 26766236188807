import { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Big from 'big.js';
import { AnimatePresence, motion } from 'framer-motion';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import globalConfig from 'config';
import cx from 'lib/cx';
import LazyView from 'components/common/LazyView';
import DonationFormAmount from './DonationFormAmount';
import DonationFormContact from './DonationFormContact';
import DonationFormConfirmation from './DonationFormConfirmation';
import DonationFormHeader from './DonationFormHeader';
import DonationFormPayment from './DonationFormPayment';
import useDonationFormAmounts from './useDonationFormAmounts';
import { setData, useData } from './useDonationFormData';
import { STEPS, useStep, setStep } from './useDonationFormStep';
import { useDonationFormWidget } from './useDonationFormWidget';
import useCustomTheme from '../../hooks/useCustomTheme';

const STEP_COMPONENT_MAP = {
  amount: DonationFormAmount,
  contact: DonationFormContact,
  payment: DonationFormPayment,
  confirmation: DonationFormConfirmation,
};

const DonationForm = ({ campaignId, config, preview, onComplete, widgetId, className }) => {
  const step = useStep(preview);
  const {
    loading,
    campaign,
    config: resolvedConfig,
  } = useDonationFormWidget({ campaignId, config, widgetId });

  const amounts = useDonationFormAmounts();
  const { type } = useData();

  const stripe = useMemo(() => {
    const { accountId, publicKey } = campaign?.ownerOrganization?.paymentProcessorConfig ?? {};
    if (!publicKey || !accountId) return null;
    return loadStripe(publicKey, { stripeAccount: accountId });
  }, [campaign?.ownerOrganization?.paymentProcessorConfig]);

  const stripeOptions = {
    ...globalConfig('/stripe/defaultProviderOptions'),
    ...(type === 'recurring_flat' && { setupFutureUsage: 'off_session' }),
    mode: 'payment',
    amount: Big(amounts.total ?? 0)
      .times(100)
      .toNumber(),
  };

  const includeStripe = stripeOptions.amount > 0;

  const StepComponent = STEP_COMPONENT_MAP[step];

  useCustomTheme({
    primary: resolvedConfig?.formAccentColor,
    secondary: resolvedConfig?.formAccentColor,
  });

  useEffect(() => () => setData({}), []);

  useEffect(() => {
    if (preview) setStep(preview);
  }, [preview]);

  return (
    <GoogleReCaptchaProvider reCaptchaKey={globalConfig('/google/reCaptchaKey')}>
      <LazyView
        fallback={
          <div
            className={cx('w-full bg-white flex justify-center items-center min-h-36', className)}
          >
            <FontAwesomeIcon icon={faSpinner} spin size="2x" className="text-gray-500" />
          </div>
        }
      >
        {!loading && campaign && (
          <div
            className={cx(
              'w-full bg-white border border-gray-400 rounded-lg flex flex-col items-stretch overflow-hidden',
              className
            )}
          >
            <DonationFormHeader isPreview={!!preview} onClose={onComplete} />
            <AnimatePresence mode="wait">
              <motion.div
                key={step}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                className="flex-1 flex flex-col overflow-hidden w-full"
              >
                {includeStripe ? (
                  <Elements stripe={stripe} options={stripeOptions}>
                    <StepComponent isPreview={!!preview} />
                  </Elements>
                ) : (
                  <StepComponent isPreview={!!preview} />
                )}
              </motion.div>
            </AnimatePresence>
          </div>
        )}
      </LazyView>
    </GoogleReCaptchaProvider>
  );
};

DonationForm.propTypes = {
  campaignId: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.shape({}),
  onComplete: PropTypes.func,
  preview: PropTypes.oneOf(STEPS),
  widgetId: PropTypes.string,
};

DonationForm.defaultProps = {
  campaignId: null,
  className: '',
  config: null,
  onComplete: null,
  preview: null,
  widgetId: null,
};

export default DonationForm;
