import { forwardRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';

import { renderAppendedInputNode } from './FormInput';
import style from './FormInput.module.css';

const FormCurrencyInput = forwardRef(
  (
    {
      status,
      disabled,
      readOnly,
      suffix,
      allowDecimals,
      decimalsLimit,
      allowNegativeValue,
      onChange,
      className,
      iconClassName,
      ...props
    },
    ref
  ) => {
    const state = useMemo(() => {
      if (disabled) return 'disabled';
      if (readOnly) return 'readonly';
      return status;
    }, [disabled, readOnly, status]);

    const statusIcon = useMemo(() => {
      if (state === 'error') return faExclamationCircle;
      if (state === 'warning') return faExclamationTriangle;
      if (state === 'success') return faCheckCircle;
      if (state === 'info') return faInfoCircle;
      return null;
    }, [state]);

    const handleChange = useCallback(
      (newValue) => {
        if (!newValue) return onChange('');
        return onChange(newValue);
      },
      [onChange]
    );

    return (
      <div className={cx('flex items-center', style.base, style[state])}>
        <div
          className={cx(
            'shrink-0 px-4 border-r border-gray-400 flex justify-center items-center',
            style.statusColor,
            iconClassName
          )}
        >
          <FontAwesomeIcon icon={faDollarSign} size="1x" />
        </div>
        <CurrencyInput
          className={cx(
            'placeholder-gray-500 focus:outline-none w-full py-2 px-4 bg-transparent',
            className
          )}
          disabled={disabled}
          readOnly={readOnly}
          allowDecimals={allowDecimals}
          decimalsLimit={decimalsLimit}
          allowNegativeValue={allowNegativeValue}
          onValueChange={handleChange}
          ref={ref}
          {...props}
        />
        {(statusIcon ?? suffix) && (
          <div className={cx('shrink-0 pr-3 transition-colors duration-100', style.statusColor)}>
            {renderAppendedInputNode(statusIcon ?? suffix)}
          </div>
        )}
      </div>
    );
  }
);

FormCurrencyInput.propTypes = {
  status: PropTypes.oneOf(['default', 'error', 'warning', 'success', 'info']),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.node, PropTypes.shape({ iconName: PropTypes.string })]),
  allowDecimals: PropTypes.bool,
  decimalsLimit: PropTypes.number,
  allowNegativeValue: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

FormCurrencyInput.defaultProps = {
  status: 'default',
  disabled: false,
  readOnly: false,
  suffix: null,
  allowDecimals: false,
  decimalsLimit: 2,
  allowNegativeValue: false,
  className: '',
  iconClassName: '',
};

export default FormCurrencyInput;
